<template>
  <div>
    <div class="font-size-md mt-5 welcomeText" style="color: #01041b">
      {{ getWelcomeText() }}
      <br />
      <small>{{ getUserRoleText() }}</small>
    </div>

    <b-skeleton-wrapper :loading="!currentUser || !currentUser.user_group">
      <template #loading>
        <b-card class="card card-custom gutter-b">
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>
      <div class="mx-4 my-4">
        <input type="text" v-model="search" @keyup.enter="sendSearch" class="form-control" placeholder="Varlık ara..." />
      </div>
      <ul class="menu-nav">
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/properties'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon-home"></i
            ><span class="menu-text">Varlıklar</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">Varlıklar</span></span
                >
              </li>
              <router-link
                to="/properties/new"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Yeni </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/properties/find"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Bul </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/properties/notes"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Notlar </span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/adverts'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon-photo-camera"></i
            ><span class="menu-text">İlanlar</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">İlanlar</span></span
                >
              </li>
              <router-link
                to="/adverts/find"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Bul </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/adverts/design"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">İlan Tasarım Sayfası </span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/transfer'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon-price-tag"></i
            ><span class="menu-text">Devir</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">Devir</span></span
                >
              </li>
              <router-link
                to="/transfer/sales"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Satış Devir </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/transfer/property"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-bullet menu-bullet-dot"><span></span></i>
                    <span class="menu-text">Varlık Devir </span>
                  </a>
                </li>
              </router-link>
            </ul>
          </div>
        </li>
        <router-link
          to="/declaration"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <li
            aria-haspopup="true"
            data-menu-toggle="hover"
            class="menu-item"
            :class="[
              isActive && 'menu-item-active',
              isExactActive && 'menu-item-active',
            ]"
          >
            <a :href="href" class="menu-link" @click="navigate">
              <i class="menu-icon flaticon-file-1"></i>
              <span class="menu-text">Emlak Beyanı </span>
            </a>
          </li>
        </router-link>
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/lists'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon2-checking"></i
            ><span class="menu-text">Listeler</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">Listeler</span></span
                >
              </li>
              <router-link
                to="/lists/monthly"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Aylık Rapolar </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/lists/sold"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Satılanlar </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/lists/offer"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Ön Teklifler </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/lists/show"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Yer Gösterim Listesi </span>
                  </a>
                </li>
              </router-link>
              
            </ul>
          </div>
        </li>
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item menu-item-submenu"
          v-bind:class="{
            'menu-item-open': hasActiveChildren('/manage'),
          }"
        >
          <a href="#" class="menu-link menu-toggle"
            ><i class="menu-icon flaticon-interface-7"></i
            ><span class="menu-text">Yönetim</span><i class="menu-arrow"></i
          ></a>
          <div class="menu-submenu" kt-hidden-height="240" style="">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
              <li aria-haspopup="true" class="menu-item menu-item-parent">
                <span class="menu-link"
                  ><span class="menu-text">Yönetim</span></span
                >
              </li>
              <router-link
                to="/manage/bank"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Satıcılar </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/manage/company"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Firma Kartı </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/manage/users/"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Kullanıcılar </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/manage/customer"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Müşteriler </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/manage/location"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">İl/İlçe/Mahalle-Köy </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/manage/feature/features"
                v-slot="{ href, navigate, isActive, isExactActive }"
                
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Özellikler Kategoriler </span>
                  </a>
                </li>
              </router-link>
              <router-link
                to="/manage/purpose/purpose-list"
                v-slot="{ href, navigate, isActive, isExactActive }"
              >
                <li
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                  class="menu-item"
                  :class="[
                    isActive && 'menu-item-active',
                    isExactActive && 'menu-item-active',
                  ]"
                >
                  <a :href="href" class="menu-link" @click="navigate">
                    <i class="menu-icon flaticon-file-1"></i>
                    <span class="menu-text">Fiili Kullanım </span>
                  </a>
                </li>
              </router-link>
              
            </ul>
          </div>
        </li>

        <hr class="menu-line d-none" />
      </ul>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGOUT } from '@/core/services/store/auth.module';

export default {
  name: 'DPMenu',
  data() {
    return {
      isActive: false,
      isExactActive: false,
      navigate: '',
      search : ""
    };
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  methods: {
    hasActiveChildren(match) {
      return this.$route['path'].indexOf(match) !== -1;
    },
    getWelcomeText() {
      if (!this.currentUser || !this.currentUser.fullName) return;

      let w = 'Merhaba, ';

      var day = new Date();
      var hr = day.getHours();
      if (hr >= 6 && hr < 12) {
        w = 'Günaydın ';
      } else if (hr >= 12 && hr < 17) {
        w = 'İyi günler ';
      } else if (hr >= 17 && hr < 22) {
        w = 'İyi Akşamlar ';
      } else {
        w = 'İyi Geceler ';
      }
      return w + this.currentUser.fullName.split(' ')[0];
    },
    getUserRoleText() {
      if (!this.currentUser || !this.currentUser.fullName) return;

      return this.$customFunctions.getUserTypeText(this.currentUser.user_group);
    },
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
    },
    sendSearch(){
        this.$router.push("properties/find?search="+this.search)
    }
  },
};
</script>
<style lang="scss">
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item.menu-item-active
  > .menu-link
  i,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: #e3f2fd !important;
  color: #2196f3 !important;
}
.menu-item .menu-submenu .menu-item.menu-item-active > .menu-link .menu-text,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link i {
  color: #2196f3 !important;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(
    .menu-item-here
  ):not(.menu-item-active):hover {
  & > .menu-link {
    background-color: #e3f2fd !important;
  }
  .menu-text {
    color: #2196f3 !important;
  }
}
</style>
